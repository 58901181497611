'use client'

import { useTranslation } from '@patrianna/core-components'
import GlobalErrorContent from 'app/components/GlobalErrorContent'
import { analyticConfig, defaultAnalyticOptions } from 'config/analytic'
import { useInitAnalytic } from '@patrianna-payments/analytics/main'

function NotFoundContent() {
  const t = useTranslation()
  useInitAnalytic(analyticConfig, defaultAnalyticOptions)

  return <GlobalErrorContent title={t('common.page_not_found')} />
}

export default NotFoundContent
